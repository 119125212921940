<template>
  <div class="box">
    <!-- <van-swipe @change="onChange" :autoplay="5000" indicator-color="white" style="height: 12rem; flex: 0 0 auto;">
      <van-swipe-item v-for="(item, index) in detailData.imgs" :key="index">
        <van-image fit="cover" :src="item" alt="" class="imgswip" />
      </van-swipe-item>
      <template #indicator>
        <ul class="indicators">
          <li class="indicator" v-for="(item, index) in detailData.imgs" :key="index"
            :class="{ active: checkActive(index) }">
          </li>
        </ul>
      </template>
    </van-swipe> -->
    <div v-if="detailData?.videoUrl && detailData?.videoUrl !== ''">
      <video-player  class="video-player vjs-custom-skin"
        ref="videoPlayer" :playsinline="true" :options="playerOptions" @pause="onPlayerPause($event)"
        @play="onPlayerPlay($event)" @ended="onPlayerEnded($event)" @ready="playerReadied">
      </video-player>
    </div>
    <div style="flex: 1;overflow-y: scroll; margin-bottom: 4rem;">
      <div class="flex" style="flex: 0 0 auto;">
        <img :src="detailData.pic" alt="" class="img" @click="showImageList" />
        <div style="flex: 1;">
          <span style="font-size: 1.2rem;"> {{ detailData.name }}</span>
          <div style="margin-top: 0.5rem;">
            <span style="color: #666; font-size: 0.8rem;">导演： {{ detailData.director }}</span>
          </div>
          <div style="margin-top: 0.5rem;">
            <span style="color: #666; font-size: 0.8rem;">{{ detailData.filmTypes }}|{{ detailData.versionTypes }}</span>
          </div>
          <div class="flex" style="margin-top: 0.5rem;font-size: 0.8rem; color: #666;">
            <span> 上映日期: {{ this.$moment(detailData.publishDate).format('YYYY.MM.DD') }}</span>
            <span style="margin-left: 1.1rem;"> {{ detailData.duration }}分钟</span>
          </div>
        </div>
      </div>

      <p style="margin-top: 1.5rem;font-size: 1.2rem;margin-left: 1.5rem;flex: 0 0 auto;">主演 </p>
      <div class="texttitle">
        <span style=" font-size: 0.8rem; color: #666;"> {{ detailData.cast }}</span>
      </div>
      <div
        style="margin-top: 1.5rem;margin-left: 1.5rem;margin-right: 1.2rem;flex: 1 1 auto;margin-bottom: 5rem;display: flex;flex-direction: column;overflow: hidden;">
        <div style="display: flex;justify-content: space-between;flex: 0 0 auto;">
          <span style="font-size: 1.1rem;"> 剧情介绍</span>
          <!-- <div @click="showTotalIntro" v-if="isShowMore">
          <p style="font-size: 0.8rem;">{{ status ? '收起' : '展开' }}</p>
        </div> -->
        </div>

        <div style="margin-top: 0.5rem; ">
          <span style="color: #666; font-size: 0.8rem;">{{ detailData.intro }}</span>
        </div>
      </div>
    </div>
    <div style="position: absolute;top:0;width: 100%;height: 12rem;" @click="onPlayerClick"></div>
    <div v-if="showButton == 1" class="addCard">
      <div class="buy" @click="goCinema">
        {{ bottom }}
      </div>
    </div>
    <div v-if="showButton == 2" class="addCard">
      <div class="buy" @click="goBack">
        返回
      </div>
    </div>
    <!-- 图片预览 -->
    <!-- <van-image-preview v-model="show" :images="images" @change="onChange">
    </van-image-preview> -->
  </div>
</template>

<script>

import BScroll from "better-scroll";
import { videoPlayer } from "vue-video-player";
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

export default {

  data() {
    return {
      showButton: 0,
      current: 0,
      // 显示展开还是收起
      status: true,
      // 是否显示展开收起按钮
      isShowMore: true,
      bescoll: null,
      detailData: {},
      cardId: '',
      isPlay: false,
      playerOptions: {
        sources: [
          {
            type: "video/mp4",
            src: '', //视频url地址
          },
        ],
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        controlBar: {
          timeDivider: false,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: false, //全屏按钮
        },
      },
      show: false,
      images: [],
      bottom: '立即购票'
    };
  },
  components: { videoPlayer },
  methods: {
    goBack() {
      this.$router.back();
    },
    showImageList() {
      this.show = true
    },
    onPlayerPause(e) {
      this.isPlay = false; //暂停
    },

    onPlayerPlay(e) {
      this.isPlay = true; //播放
    },
    onPlayerEnded(e) {
      this.isPlay = false; //暂停

    },
    onPlayerClick() {
      if (this.isPlay) {
        this.player.pause();
      } else {
        this.player.play();
      }
    },
    playerReadied() {
      this.player = this.$refs.videoPlayer.player;
      this.player.play();
      let timer = setInterval(() => {
        this.player.pause();
        clearInterval(timer);
      }, 200);
    },
    goCinema(val) {
      this.$router.push({
        path: "/Cinema",
        query: {
          filmId: this.detailData.filmId,
          movieName: this.detailData.name,
        },
      });
    },
    initImages() {
      this.images = this.detailData.imgs
    },
    checkActive(index) {
      return index === this.current;// current为监听的轮播index
    },
    onChange(index) {
      this.current = index;
    },
  },
  mounted() {
    this.detailData = JSON.parse(this.$route.query.item)
    this.playerOptions.sources[0].src = this.detailData.videoUrl
    this.playerOptions.poster = this.detailData.pic
    this.cardId = localStorage.getItem("cardId")
    this.showButton = this.$route.query.showbutton
    this.bescoll = new BScroll(this.$refs.wrapper)

    document.title = this.detailData.name
    this.initImages()
  },
  computed: {},
  watch: {
  },
};
</script>

<style lang='scss' scoped>
.box {
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .img {
    width: 6rem;
    height: 8rem;
    border-radius: 0.5rem;
    margin: 1rem 1.5rem;
    box-shadow: 0 0 0.5rem #888;
  }
}

.imgswip {
  width: 100%;
  height: 100%;
  background-color: black;
}

.texttitle {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  overflow: hidden;
  margin-top: 0.5rem;
  flex: 0 0 auto;
}

.textcontext {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.wrapper {
  height: 100%;
}

.textcontext1 {
  overflow: hidden;
  display: -webkit-box;
}

.recomen {
  padding: 0.2rem;
  text-align: center;
  width: 40%;
  height: 10rem;
}

.img2 {
  height: 5rem;
  width: 7rem;
  border-radius: 0.3rem;
}

::v-deep .video-js .vjs-big-play-button {
  /*
     播放按钮换成圆形
    */
  left: 55%;
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 1em;
}

.unfold {
  z-index: 11;
  width: 40px;
  height: 21px;
  outline: 0;
  position: absolute;
  right: 0;
  bottom: 0;
}

.addCard {
  position: absolute;
  width: 90%;
  margin-left: 5%;
  padding-top: 0.5rem;
  bottom: 1rem;
}

.buy {

  border-radius: 1.5rem;
  padding: 0.8rem 0;
  width: 100%;
  font-size: 0.8rem;
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);
  color: #f5f5f5;
  text-align: center;
}

.indicators {
  position: absolute;
  bottom: 5px;
  right: 10px;
  display: flex;
  transform: translateX(30%);

  .indicator {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.6;
    transition: opacity 0.5s, background-color 0.5s;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &:last-child {
      margin-right: 4px;
    }

    &.active {
      width: 1.2rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      opacity: 1;
    }
  }
}
</style>